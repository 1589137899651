import React from 'react'
import styled from 'styled-components'
import { ReUseHOneTag } from './ReUseHOneTag'
// import { ReuseImgTag } from './ReuseImgTag'
import SOCReportData from '../Data/SOCReportData.json'

const AppSOCReportWpper = styled.div`
  max-width: 1920px;
  margin: auto;
  .MainSCOsection {
    border-radius: 32px;
    background-color: #77aa77;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 2 1'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%2377aa77'/%3E%3Cstop offset='1' stop-color='%234fd'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='0' y2='1'%3E%3Cstop offset='0' stop-color='%23cf8' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23cf8' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='c' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='2' y2='2'%3E%3Cstop offset='0' stop-color='%23cf8' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23cf8' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='0' y='0' fill='url(%23a)' width='2' height='1'/%3E%3Cg fill-opacity='0.5'%3E%3Cpolygon fill='url(%23b)' points='0 1 0 0 2 0'/%3E%3Cpolygon fill='url(%23c)' points='2 1 2 0 0 0'/%3E%3C/g%3E%3C/svg%3E");
    background-size: cover;
    background-position: center; /* Ensure the background is centered */
    padding: 100px 96px 100px 96px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 1080px) {
      flex-wrap: wrap;
      padding: 80px 15px 50px 15px;
      border-radius: 32px;
      background-color: #151936;
    }
  }
  .SOCBannerHeading {
    /* max-width: 650px; */
    line-height: 1.30435em;
    padding-bottom: 3%;
    padding-top: 2%;
    font-size: 2.5rem;
    color: #111;
    /* @media (max-width: 1540px) {
      max-width: 750px;
    } */
    @media (max-width: 1080px) {
      padding-top: unset;
      max-width: unset;
    }
  }
  .HeadingSection {
    @media (max-width: 1080px) {
      margin: auto;
      order: 1;
    }
  }
  .ImageSection {
    @media (max-width: 1080px) {
      margin: auto;
      order: 0;
    }
  }
  .SCOBannerimage {
    width: 80%;
    height: auto;
    @media (max-width: 1080px) {
      margin-top: 0%;
      float: unset;
      width: 100%;
      justify-content: center;
    }
  }
  .SecurityCard {
    cursor: pointer;
  }
`

export const AppSOCReport = () => {
  return (
    <AppSOCReportWpper>
      {SOCReportData.map((data, index) => {
        return (
          <div className="MainSCOsection" key={index}>
            <div className="HeadingSection">
              <ReUseHOneTag Heading={data.title} HeadingStyle="SOCBannerHeading" />
            </div>
            <div className="ImageSection">
              {/* <ReuseImgTag ImageStyle="SCOBannerimage" ImagePath={data.ImagePath} AltName={data.AltName} /> */}
              <iframe
                src="https://securityscorecard.com/security-rating/badge/raindrop.com"
                width="256vw"
                height="100vh"
                frameBorder="0"
                className="SecurityCard"
              ></iframe>
            </div>
          </div>
        )
      })}
    </AppSOCReportWpper>
  )
}
